import axios from "axios";
import parsePhoneNumber from "libphonenumber-js";
import { api } from "../../helpers/topline-api";

export async function check_existence(type: string, contact: string) {
  try {
    const res_check = await api.post(`/api/check/${type}`, {
      payload: contact,
    });
    if (res_check?.data?.status === "success") {
      const customer = res_check?.data?.message;
      return customer;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

export async function checkTeammate(phone: string, email: string) {
  try {
    const response = await api.post(`/api/check-teammate`, {
      phone,
      email,
    });
    if (response?.data?.status === "success") {
      return response?.data?.message;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export const sendOtpToUser = async (phoneNumber: string | undefined) => {
  const res = await axios.post("/api/verification", {
    to: phoneNumber,
    channel: "sms",
    type: "send",
  });
  return res.data;
};

export const verifyOtp = async (
  phoneNumber: string | undefined,
  otp: string | undefined
) => {
  const res = await axios.post("/api/verification", {
    to: phoneNumber,
    channel: "sms",
    type: "check",
    code: otp,
  });
  return res.data;
};

export const createAuth0User = async (
  email: string | undefined,
  phoneNumber: string | undefined,
  firstName: string | undefined,
  lastName: string | undefined,
  accessToken: string | undefined
) => {
  const res = await axios.post("/api/verification", {
    type: "new_user",
    access_token: accessToken,
    email: email,
    //contact will be the parsed version of the phone number
    phone: phoneNumber,
    "first-name": firstName,
    "last-name": lastName,
  });

  return res.data;
};

export const getNumberFromString = (str: string) => {
  const number = str.replace(/[^0-9]/g, "");
  return number;
};

export const getFormattedPhoneNumber = (phoneNumber: string | undefined) => {
  try {
    if (phoneNumber) {
      const formattedNumber = parsePhoneNumber(phoneNumber, "US")
        ?.formatInternational()
        .replace(/\s/g, "");
      if (formattedNumber) {
        return formattedNumber;
      }
    }
    return phoneNumber;
  } catch (e) {
    return phoneNumber;
  }
};
